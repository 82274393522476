<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('fund.fabu')"
        left-arrow
        @click-left="$router.go(-1)"
      >
      </van-nav-bar>
    </div>
    <div class="maincontent">
      <!-- 信用额度 -->
      <van-row class="publish">
        <van-col :span="24" style="padding:15px">
          <van-row style="text-align:left">
            <span style="padding-left:20px">
              {{ info.title }}
            </span>
          </van-row>
          <van-row class="publish-msg">
            <span>{{ $t('fund.credit') }}</span>
            <van-row class="publish-rate">
              <span
                >{{ info.credit }}
                <span style="font-size:15px"></span>
              </span>
            </van-row>
          </van-row>
        </van-col>
      </van-row>
      <!-- 发布额度 -->
      <van-row class="publish">
        <van-col :span="24" style="padding:15px">
          <van-row style="text-align:left">
            <span style="padding-left:20px">{{ $t('fund.fbed') }}</span>
          </van-row>
          <van-row class="currency-information">
            <van-row
              class="information"
              v-for="(item, index) in info.values"
              :key="index"
              @click="v_choose(index)"
              :class="v_active === index ? 'bg' : ''"
            >
              <span>{{ item }}</span>
            </van-row>
          </van-row>
        </van-col>
        <!-- 另外一种样式 -->
        <van-col :span="24" style="padding:15px;display:none">
          <van-row style="text-align:left">
            <span style="padding-left:20px">{{ $t('fund.fbed') }}</span>
          </van-row>
          <van-field placeholder="请输入" />
        </van-col>
      </van-row>
      <!-- 发布期限 -->
      <van-row class="publish">
        <van-col :span="24" style="padding:15px">
          <van-row style="text-align:left">
            {{ $t('fund.fbqx') }}
          </van-row>
          <van-row class="currency-information">
            <van-row
              class="information"
              v-for="(item, index) in info.times"
              :key="index"
              @click="p_choose(index)"
              :class="p_active === index ? 'bg' : ''"
            >
              <span>{{ item }}天</span>
            </van-row>
          </van-row>
        </van-col>
      </van-row>
      <!-- 按钮 -->
      <van-button v-if="info.oauth === '4'" class="submitBtn" @click="submit">{{
        $t('fund.submit')
      }}</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '',
      day: '',
      info: {},
      v_active: '',
      p_active: ''
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get(
        '/home/fund/infodetail/id/' + this.$route.params.id
      )
      if (data) {
        if (data.code === 200) {
          this.info = data.data
        }
      }
    },
    v_choose(index) {
      this.v_active = index
      this.value = this.info.values[index]
    },
    p_choose(index) {
      this.p_active = index
      this.day = this.info.times[index]
    },
    async submit() {
      if (!this.day || !this.value) {
        this.$toast.fail('请选择')
        return false
      }
      const { data } = await this.$http.post('/home/fund/addpublish', {
        day: this.day,
        value: this.value,
        id: this.info.id
      })
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('common.success'))
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.maincontent {
  padding: 55px 0 0 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .publish {
    margin-top: 0.23333rem;
    margin-bottom: 0.23333rem;
    background-color: #fff;
    display: flex;
    justify-content: center;
    color: #000;
    .publish-msg {
      margin-top: 1.33333rem;
      font-size: 0.8rem;
    }
    .publish-rate {
      font-size: 2.66667rem;
      margin-top: 1.06667rem;
    }
    .currency-information {
      margin: 1.06667rem;
      line-height: 1.33333rem;
      font-size: 0.93333rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .information {
        flex: 1;
        margin: 1%;
        width: 31%;
        min-width: 31%;
        max-width: 31%;
        height: 2.66667rem;
        background-color: #fff;
        border-radius: 0.26667rem;
        border: 0.02667rem solid #232b40;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .bg {
        background-color: #004ea3;
        color: #fff;
      }
    }
    .van-field__control {
      border-bottom: 1px solid #666;
    }
    .van-field input {
      color: #000;
      border-bottom: 0.02667rem solid #868686;
    }
  }
  .submitBtn {
    margin-top: 10%;
    margin-left: 10%;
    width: 80%;
    letter-spacing: 0.53333rem;
    height: 4rem;
    font-size: 0.93333rem;
    color: #fff;
    background-color: #004ea3;
    border-radius: 0.53333rem;
    border: none;
    box-shadow: 0 0 1.33333rem #004ea3;
  }
}
</style>
